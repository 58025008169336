import * as React from "react";
import { navigate } from "gatsby";

import Tail from "../components/tail";

const NotFoundPage = () => {
  React.useEffect(() => {
    setTimeout(() => {
      navigate("/"); // redirecting to home page
    }, 200)
  }, []);

  return (
    <Tail>
      <h1>404: Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Tail>
  );
};

export default NotFoundPage;
